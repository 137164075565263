import styles from './styles.module.scss';
import { Button, TextField, Typography } from '@mui/material';
import LoginSignupSwitcher from '../LoginSignupSwitcher';
import { useRef, useState } from 'react';
import { Box } from '@mui/system';
import { useCallback } from 'react';
import { Auth } from 'aws-amplify';
import useGlobalState from '../GlobalStateManager';
import { useEffect } from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import Lambda from 'aws-sdk/clients/lambda'; 
import SecureShield from 'Assets/img/SecureShield.png'
import StripeLogo from 'Assets/img/StripeLogo.png'
const SignUpFormInner=({})=>{
    const stripe = useStripe();
    const elements = useElements(); 
    const {setLoadingModalContent, setUser, setMainModalContent} = useGlobalState((state)=>({setLoadingModalContent:state.setLoadingModalContent,setUser:state.setUser,setMainModalContent:state.setMainModalContent}))

    const emailRef = useRef();
    const passwordRef = useRef();
    
    const [generalError,setGeneralError] = useState();
    const [emailError,setEmailError] = useState();
    const [pwError,setPwError] = useState();

    const validateInputs = useCallback(()=>{
        const email = emailRef.current.querySelector('input').value
        const password = passwordRef.current.querySelector('input').value
        setEmailError('');
        setPwError('');
        if (!(/[a-zA-Z0-9@!$_\-.\(\)\[\]\#\~\:]{3,}@[a-zA-Z0-9@!$*_\-.\(\)\[\]\#\~\:]{3,}\..{2,}/gmi).test(email)) {
          setEmailError('Email must be a properly formatted email address (no spaces, no special chars)')
          return false;
        }
        else if (!(/[^\/\\\{\}\<\>]{4,}/gmi).test(password)) {
          setPwError('Please enter at least 4 characters.')
          setLoadingModalContent(null);
          return false;
        }
        return true;
        
    },[]);


    const resetFormErrors = useCallback(()=>{
        setGeneralError(null);
        setEmailError(null);
        setPwError(null);
    },[])

    const doSignup = useCallback(()=>{
        if (!validateInputs()) return;
        const email = emailRef.current.querySelector('input').value
        const password = passwordRef.current.querySelector('input').value

        Auth.signUp({
            username: email,
            password: password,
            attributes: {
                email: email,
                // purchaseID: 'TODO IMPLEMENT PURCHASEID PASSTHROUGH'
            }
        })
        .then((user)=>{
            resetFormErrors();
            Auth.signIn(email,password)
            .then((res)=>{
                setUser(res);
                setLoadingModalContent(null);
                setMainModalContent(null); //TODO ADD WELCOME MODAL
            })
            .catch((err)=>{

            })
            
        })
        .catch((err)=>{
            const _err = String(err);
            if (_err.indexOf('UsernameExistsException') !== -1) {
            // hideLoadingModal();
            setLoadingModalContent(<span>Looks like this email has already been signed up! Would you care to <span style={{textDecoration:'underline',cursor:'pointer'}} onClick={()=>{setLoadingModalContent(null)}}>sign in</span>?</span>)
            // setMiscError(<span>Looks like this email has already been signed up! Would you care to <span style={{textDecoration:'underline',cursor:'pointer'}} onClick={()=>{}}>sign in</span>?</span>)
        }

        })
    
    },[]);

    const doCheckout = useCallback(async (e)=>{
        e.preventDefault();
        e.stopPropagation();
        if (!elements) return
        if (!validateInputs()) return;
        setLoadingModalContent('Processing payment...');
        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
        });

        // BE lambda call
        const unAuthCreds = await Auth.currentCredentials();
        const lambda = new Lambda({
            credentials: Auth.essentialCredentials(unAuthCreds),
            region: 'us-west-1'
        })
        const doCheckoutBEFunctionParams = {
            FunctionName: `${process.env.REACT_APP_BE_CHECKOUT_ENDPOINT}`,
            Payload: JSON.stringify({amount:500,currency:'usd'})
        }
        lambda.invoke(doCheckoutBEFunctionParams,async (err,dat)=>{
            if (err) {
                setLoadingModalContent('Uh oh, it seems there was an error. Please try again shortly.')
                setTimeout(()=>{setLoadingModalContent(null)},1500)
            }
            else {
                let paymentIntent = JSON.parse(dat.Payload)
                paymentIntent = JSON.parse(paymentIntent.body)

                //successful intent generation proceed with purchase attempt
               stripe.confirmCardPayment(paymentIntent.paymentIntent.client_secret, {
                    payment_method: {
                        card: elements.getElement(CardElement),
                        billing_details: {
                            email: emailRef.current.querySelector('input').value
                        },
                    },
                })
                .then((result)=>{
                    if (result.error) {
                        setGeneralError(result.error.message);
                        setLoadingModalContent(null);
                    }
                    else{
                        setLoadingModalContent('Stamping your intergalactic passport...');
                        doSignup();
                    }
                    
                })
                .catch((err)=>{
                    console.log('checkout error:',err)
                })
            }
        })
        
        
        
        //todo build
    },[stripe,elements])
    return (
    <>
        <form onSubmit={doCheckout}>
            <Box sx={{display:'flex',flexDirection:'column',padding:'20px'}}>
                <h1>Purchase a Ticket for Xen-Gallery</h1>
                <TextField variant="outlined" size="small" helperText={emailError? emailError : "Email"} ref={emailRef} error={!!emailError}/>
                <TextField variant="outlined" size="small" helperText={pwError? pwError : "Password"} type="password"  ref={passwordRef} error={!!pwError}/>
                <Box>
                    <img src={SecureShield} style={{height: '25px', width: 'auto', position: 'relative', top:'6px'}} /><Typography sx={{display:'inline'}}>Secure checkout with </Typography><img src={StripeLogo} style={{height: '26px', width: 'auto', position: 'relative', top:'8px', left:'-3px'}} />
                </Box>
                {!!generalError && <Box>
                        <Typography sx={{color:'red'}}>Error {generalError}</Typography>
                    </Box>}
                <Box sx={{background:'#eee',border: 'solid 1px #333'}}>
                    <CardElement theme="night" options={{appearance:{theme:'night',rules:{'.Input':{border:'solid 3px #f00'}}},style:{ '.Input':{border:'solid 3px #0f0'}, base:{fontSize:'20px',border:'solid 3px #00f','.Input':{border:'solid 3px #0f0'}},'.Input':{border:'solid 3px #0f0'}}}} />    
                </Box>
                {/* <PaymentElement /> */}
                <Button type="submit" sx={{margin:'20px 0 0 0'}} disabled={!stripe||!elements} variant="outlined">Buy Ticket</Button>
            </Box>
            <LoginSignupSwitcher/>
        </form>
    </>
    )
}

const SignUpForm=({})=>{
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBKEY);

    return (
        <>
            <Elements stripe={stripePromise} options={{appearance:{theme:'night'}}}>
                <SignUpFormInner />
            </Elements>
        </>
    )
}

export default SignUpForm