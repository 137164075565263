import { CuboidCollider, RigidBody } from "@react-three/rapier"

const GroundPlane=(props)=>{ 
  return (
    <RigidBody {...props} type="fixed" colliders={false}>
      <mesh position={[0,-1,0]}>
        <gridHelper args={[10000,20000]} />
        <meshBasicMaterial color={'#00f'} />
      </mesh>
      <CuboidCollider args={[1000, 2, 2000]} position={[0, -3.4, 0]} />
    </RigidBody>
    
  )
}

export default GroundPlane