import { DoubleSide, TextureLoader } from 'three';
import { useLoader, useFrame } from "@react-three/fiber";
import missingAsset from 'Assets/tex/missingAsset.png';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useGlobalState from '../../../GlobalStateManager';
import { Edges, GradientTexture } from '@react-three/drei';
import GallItemInfo from '../../../GallItemInfo';
const GalleryItem=({item, position})=>{
  
  const [hovered,setHovered] = useState(false);
  const [showingContent,setShowingContent] = useState(false);
  const {setNameModalContent,setMainModalContent,setGalleryDetailModalContent} = useGlobalState((state)=>({setMainModalContent:state.setMainModalContent,setNameModalContent:state.setNameModalContent,setGalleryDetailModalContent:state.setGalleryDetailModalContent}));
  const itemAsset = useMemo(()=>{
    return !!item?.itemAsset? item.itemAsset : missingAsset
  },[item])
  const _itemAsset= useLoader(TextureLoader, itemAsset); 

  const itemWidth = useMemo(()=>{
    return item.metadata.dimensions[0]/2000;
  },[item])

  const itemHeight = useMemo(()=>{
    return item.metadata.dimensions[1]/2000;
  },[item])
  console.log(`!GI ${item.itemName} itemWidth=`,itemWidth)

  const doPointerOver = useCallback(()=>{
    setNameModalContent(item.itemName)
    setHovered(true);
  },[])

  const doPointerOut = useCallback(()=>{
    setNameModalContent(null)
    setHovered(false);
  },[])

  const doClick = useCallback(()=>{
    if (hovered && !showingContent) {
      setGalleryDetailModalContent(<GallItemInfo item={item}/>) 
    }
    else if (showingContent) {
      setShowingContent(false);
      setGalleryDetailModalContent(null);
    }
  },[hovered])

  return (
    <group position={position}>
      <mesh  onPointerOver={doPointerOver} onPointerOut={doPointerOut} onClick={doClick}>
        <planeBufferGeometry args={[itemWidth,itemHeight]}/>
        <meshLambertMaterial map={_itemAsset} side={DoubleSide} transparent/>
        <Edges visible={hovered} scale={1.1} renderOrder={1000}>
          <meshBasicMaterial color="#fff" />
        </Edges>
      </mesh>
     {/* {!!hovered && <mesh position={[0,0,-0.01]}>
        <planeBufferGeometry args={[itemWidth*1.1,itemHeight*1.1,1,1]}/>
        <meshBasicMaterial color="#fff" wireframe={true}>
          {/* <GradientTexture
              stops={[0, 1]} // As many stops as you want
              colors={['aquamarine', 'hotpink']} // Colors need to match the number of stops
              size={1024} // Size is optional, default = 1024
            /> */}
        {/* </meshBasicMaterial>
      </mesh>} */} 
  </group>
  )
}

export default GalleryItem