//import styles from './styles.module.scss';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import BasePageTemplate from "../Templates/BasePageTemplate";
import { useCallback } from 'react';
import useGlobalState from '../../Components/GlobalStateManager';
import SignUpForm from 'Components/SignUpForm';
import { useEffect } from 'react';
import XenGalleryTitle from 'Assets/img/XenGalleryTitle.png'
import XenGalleryTickie from 'Assets/img/XenGalleryTickie.png'
import styles from './styles.module.scss'
const LoginSignupPage=({})=>{
  const {setModalContent} = useGlobalState((state)=>({setModalContent:state.setMainModalContent}));
  const {setLoadingModalContent, setUser, setMainModalContent} = useGlobalState((state)=>({setLoadingModalContent:state.setLoadingModalContent,setUser:state.setUser,setMainModalContent:state.setMainModalContent}))
  const showLoginSignup = useCallback(()=>{
    setModalContent(<SignUpForm/>)
  },[])
  return (
    <BasePageTemplate>
      <img onClick={showLoginSignup} src={XenGalleryTickie} className={styles.tickie}/>
      <img src={XenGalleryTitle} className={styles.title}/>
    </BasePageTemplate>
  )
}

export default LoginSignupPage