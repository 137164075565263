//import styles from './styles.module.scss';
import { Canvas, useFrame } from '@react-three/fiber'
import { useCallback, useEffect, useRef } from 'react';
import { degToRad } from 'three/src/math/MathUtils';
import GalleryItem from '../Gallery/GalleryItem';
import GalleryRow from '../Gallery/GalleryRow';
import GroundPlane from '../GroundPlane';
import PlayerControl from '../PlayerControl';
import { PointerLockControls } from "@react-three/drei"
import SkyBox from '../Skybox';
import PlayerAvatar from '../PlayerAvatar';
import { Physics } from "@react-three/rapier"
import Row1 from '../../../GalleryData/Row1';
import useGlobalState from '../../GlobalStateManager';
import NameModal from '../../NameModal';
import VelRope from '../VelRope';
import Row2 from '../../../GalleryData/Row2';
import Row3 from '../../../GalleryData/Row3';
import Row4 from '../../../GalleryData/Row4';
import Row5 from '../../../GalleryData/Row5';
import Invisiwall from '../InvisiWall';
import { VRButton } from '@react-three/xr'
const WorldBase=({})=>{
  
  const {nameModalContent,setNameModalContent, setPointerLockRef, pointerLockRef, userControlsPaused} = useGlobalState((state)=>({nameModalContent:state.nameModal,setNameModalContent:state.setNameModalContent, pointerLockRef:state.pointerLockRef, setPointerLockRef:state.setPointerLockRef,userControlsPaused:state.userControlsPaused}))
  const _pointerLockRef = useRef();
  useEffect(()=>{
    setPointerLockRef(_pointerLockRef);
  },[setPointerLockRef])

  useEffect(()=>{
    if (userControlsPaused) {
      setTimeout(()=>{_pointerLockRef.current?.unlock();},50)
    }
    else {
      setTimeout(()=>{_pointerLockRef.current?.lock();},50)
    }
  },[userControlsPaused])
  
  return (
    <>
      <PlayerControl paused={userControlsPaused}>
        <Canvas>
          <ambientLight />
          <pointLight position={[10,10,10]}/>
          <SkyBox />
          <GalleryRow rowMeta={Row1} />
          <GalleryRow rowMeta={Row2} />
          <GalleryRow rowMeta={Row3} />
          <GalleryRow rowMeta={Row4} />
          <GalleryRow rowMeta={Row5} />
          <Physics gravity={[0, -30, 0]}>
            <PlayerAvatar paused={userControlsPaused} position={[0,0,7]} />
            <VelRope position={[0,0,9]}/>
            <VelRope position={[-2.9,0,6]} rotation={[0,degToRad(90),0]}/>
            <VelRope position={[2.9,0,6]} rotation={[0,degToRad(90),0]}/>
            <VelRope position={[9,0,-5.5]} rotation={[0,degToRad(90),0]}/>
            <VelRope position={[9,0,-8.5]} rotation={[0,degToRad(90),0]}/>
            <VelRope position={[-9,0,-5.5]} rotation={[0,degToRad(90),0]}/>
            <VelRope position={[-9,0,-8.5]} rotation={[0,degToRad(90),0]}/>
            <Invisiwall position={[0,0,9]} width={6} />
            <Invisiwall position={[-2.9,0,3.4]} rotation={[0,degToRad(90),0]} width={14} />
            <Invisiwall position={[2.9,0,3.4]} rotation={[0,degToRad(-90),0]} width={14} />
            <Invisiwall position={[0,0,-10]} rotation={[0,degToRad(180),0]} width={20} />
            <Invisiwall position={[-6,0,-3.6]} rotation={[0,0,0]} width={6} />
            <Invisiwall position={[6,0,-3.6]} rotation={[0,0,0]} width={6} />
            <Invisiwall position={[9,0,-6.8]} rotation={[0,degToRad(-90),0]} width={6} />
            <Invisiwall position={[-9,0,-6.8]} rotation={[0,degToRad(90),0]} width={6} />
            <GroundPlane />
          </Physics>
          <PointerLockControls ref={pointerLockRef} />
        </Canvas>
      </PlayerControl>
      <NameModal />
      <VRButton />
    </>
  )
}

export default WorldBase