import {create} from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware'
import produce from 'immer'
// import {API} from 'aws-amplify'
// import { debounce, isArray } from 'lodash';

const useGlobalState = create(subscribeWithSelector((set,get)=>({
  user: null,
  mainModalContent: null,
  setMainModalContent: (newContent)=>{set({mainModalContent:newContent})},
  loadingModalContent: null,
  setLoadingModalContent: (newContent)=>{set({loadingModalContent:newContent})},
  nameModalContent: null,
  setNameModalContent: (newContent)=>{set({nameModalContent:newContent})},
  galleryDetailModalContent: null,
  setGalleryDetailModalContent: (newContent)=>{set({galleryDetailModalContent:newContent})},
  user: null,
  setUser: (newUser)=>{set({user:newUser})},
  pointerLockRef: null,
  setPointerLockRef: (newRef)=>{set({pointerLockRef:newRef})},
  userControlsPaused: false,
  setUserControlsPaused: (newState)=>{set({userControlsPaused:newState})}
  // foo: 'bar',
  // setFoo: (newFoo)=>{console.log('newFoo',newFoo)}
  // setUser:(newUser) => {
  //   // const accessTokenPayload = newUser.getSignInUserSession()?.getAccessToken().payload;
  //   // newUser.groups = accessTokenPayload ? accessTokenPayload['cognito:groups'] : []
  //   // console.log('GSM setting new user',newUser);
  //   set({ user:newUser })
  // },
  // userData: {},
  // userDataLastRefreshed: 0,
  // setUserData:(newData)=>{
  //   const refreshTime=Date.now();
  //   console.log('GSM setting user data to',newData);
  //   set({userData:newData,userDataLastRefreshed:refreshTime})
  //   console.log('userData is now',get().userData);
  //   return refreshTime
  // },
  // refreshUserData: async ()=>{
  //   return new Promise(async (res,rej)=>{
  //     //query inventory
  //     const userEmail = get()?.user?.attributes?.email;
  //     console.log('GSM refreshUserData userEmail=',userEmail);
  //     if (!userEmail) return
  //     const playerDataQuery = `query getPlayerData {
  //       getPlayer(email: "${userEmail}") {
  //         email
  //         walletAddress
  //         currentObjective {
  //           name
  //           title
  //           body
  //           type
  //           miscData
  //         }
  //         inventory {
  //           playerEmail
  //           belongsToPlayer {
  //             email
  //           }
  //           contents {
  //             items {
  //               variant {
  //                 name
  //                 id
  //                 forItemType {
  //                   name
  //                   id
  //                 }
  //               }
  //               itemId
  //               itemVariantId
  //             }
  //           }
  //         }
  //       }
  //     }
  //         `
  //     try {
  //       const resp = await API.graphql({query: playerDataQuery});
  //       if (resp?.body?.errors) {
  //         console.log('error refreshing player data,',resp.body.errors)
  //         rej('error refreshing player data,',resp.body.errors)
  //       }
  //       else if (resp?.data?.getPlayer) {
  //         get().setUserData(resp.data.getPlayer)
  //         res(resp.data.getPlayer);
  //       }
  //     }
  //     catch(err){
  //       console.log('error refreshing player data,',err)
  //       rej('error refreshing player data,',err);
  //     }
  //   })
  // },
  // debouncedRefreshUserData: debounce(()=>{get().refreshUserData();},1000),
  // writeUserDataToBE: async (newData)=>{
  //   return new Promise(async(res,rej)=>{
  //     console.log('!IB GSM writeUserDataToBE newData.player.currentObjective',newData.player.currentObjective);
  //     //if a wallet address or Player table change
  //     if (newData.player) { // await all mutations for player table
        
  //       if (newData?.player?.walletAddress !== undefined && newData?.player?.walletAddress !== null) {
  //         console.log('wallet address mutation detected')
  //         const userEmail = get()?.user?.attributes?.email;
  //         if (!userEmail) {
  //           console.log('err could not determine user email to update wallet address')
  //           return
  //         }
  //         const walletAddressMutation = `mutation MyMutation {
  //           updatePlayer(input: {email: "hello@ultraplush.io", walletAddress: ""}) {
  //             email
  //             walletAddress
  //           }
  //         }
  //         `
  //         let resp;
  //         try {
  //           resp = await API.graphql({query: walletAddressMutation})
  //         }
  //         catch(err){
  //           console.log('error making api call',err)
  //         }
  //         console.log('success making api call',resp);
  //         // API.graphql({query: walletAddressMutation})
  //         // .then((resp)=>{
  //         //   console.log('GSM walletAddressMutation query success resp:',resp)
  
  //         //   // if (resp === "ok") {
  //         //   //   refreshUserData();
  //         //   // }
  //         // })
  //         // .catch((err)=>{console.log('walletAddressMutation api hit err err:',err)})
  //       }
  //       else if (newData.player.currentObjective) {
  //         console.log('!IB GSM updating player currentObjective on BE to',newData?.player?.currentObjective);
  //         const userEmail = get()?.user?.attributes?.email;
  //         if (!userEmail) {
  //           console.log('err could not determine user email to update user objective')
  //           return
  //         }
  //         const updatePlayerObjectiveMutation = `mutation updatePlayerObjective {
  //           updatePlayer(input: {email:"${userEmail}",playerCurrentObjectiveId: "${newData?.player?.currentObjective}"}) {
  //             playerCurrentObjectiveId
  //           }
  //         }`
  //         try {
  //           const resp = await API.graphql({query: updatePlayerObjectiveMutation});
  //           console.log('!IB updatePlayerObjectiveMutation resp:',resp);
  //           if (resp?.body?.errors) {
  //             console.log('error updating user objective,',resp.body.errors)
  //             rej('error updating user objective,',resp.body.errors)
  //           }
  //           else if (resp?.data) {
  //             // console.log('!IB update user resp?.data?.updatePlayer?.playerCurrentObjectiveId:',resp?.data?.updatePlayer?.playerCurrentObjectiveId,'resp?.data?.updatePlayer?.playerCurrentObjectiveId===newData?.player?.currentObjective',resp?.data?.updatePlayer?.playerCurrentObjectiveId===newData?.player?.currentObjective);
  //             // get().setWorldData(resp.data.getWorld)
  //             // await get().writeUserDataToBE({player:{currentObjective:resp.data.getAct1ClueWord.objective.name}})
  //             // res(resp.data.getAct1ClueWord)
  //             get().refreshCurrentObjective();
  //           }
  //         }
  //         catch(err){
  //           console.log('error refreshing group data,',err)
  //           rej('error refreshing group data,',err)
  //         }
  //       }
  //     }
  //     //if inventory changes
  //     if (newData.inventory) {
  //       //await mutation for inventory table
  //     }
  //   })

  //   // get().refreshUserData() // AFTER AWAIT
  // },
  // worldData: {},
  // worldDataLastRefreshed: 0,
  // setWorldData: (newData)=>{
  //   const refreshTime=Date.now();
  //   console.log('GSM setting world data to',newData);
  //   set({worldData:newData,worldDataLastRefreshed:refreshTime})
  //   console.log('worldData is now',get().worldData);
  //   return refreshTime
  // },
  // refreshWorldData: async()=>{
  //   return new Promise(async(res,rej)=>{
  //     const worldDataQuery = `query getWorld {
  //       getWorld(name: "world") {
  //         worldObjective {
  //           body
  //           createdAt
  //           miscData
  //           name
  //           title
  //           type
  //           updatedAt
  //         }
  //         overrideObjective {
  //           body
  //           createdAt
  //           miscData
  //           name
  //           title
  //           type
  //           updatedAt
  //         }
  //       }
  //     }`;
  //     try {
  //       const resp = await API.graphql({query: worldDataQuery});
  //       console.log('world data api hit refresh, resp:',resp)
  //       if (resp?.body?.errors) {
  //         console.log('error refreshing world data,',resp.body.errors)
  //         rej('error refreshing world data,',resp.body.errors)
  //       }
  //       else if (resp?.data?.getWorld) {
  //         get().setWorldData(resp.data.getWorld)
  //         res(resp.data.getWorld)
  //       }
  //     }
  //     catch(err){
  //       console.log('error refreshing group data,',err)
  //       rej('error refreshing group data,',err)
  //     }
  //   })
  // },
  // refreshMultipleDataSources: async(sources)=>{
  //   return new Promise(async(res,rej)=>{
  //     const promises = []
  //     if (isArray(sources)) {
  //       sources.map((source)=>{
  //         if (source === "world") {
  //           promises.push(get().refreshWorldData());
  //         }
  //         else if (source === "group") {
  //           promises.push(get().refreshGroupData());
  //         }
  //         else if (source === "player") {
  //           promises.push(get().refreshUserData())
  //         }
  //         else if (source === "user") {
  //           promises.push(get().refreshUserData())
  //         }
  //       })
  //     }
  //     Promise.all(promises).then(()=>{res('ok')})
  //   })    
  // },
  // loadingModalVariant: 'default',
  // loadingModalAutoCloseTimer: null,
  // loadingModalContent: '',
  // showLoadingModal: (content='loading',variant='default',duration=0)=>{
  //   console.log('!@ showLoadingModal content',content,'variant',duration,'duration')    
  //   if (duration) {  // show a "fake" loading modal for a set period of time to indicate to users that activity is happening.
  //     const loadingModalAutoCloseTimer = get().loadingModalAutoCloseTimer
  //     if(loadingModalAutoCloseTimer) {
  //       clearTimeout(get().loadingModalAutoCloseTimer)
  //     }
  //     const timerId = setTimeout(()=>{
  //       get().hideLoadingModal();
  //     },duration)
  //     set({loadingModalVariant:variant,loadingModalContent:content,loadingModalAutoCloseTimer:timerId})
  //   }
  //   else {
  //     set({loadingModalVariant:variant,loadingModalContent:content})
  //   }
  // },
  // hideLoadingModal: ()=>{set({loadingModalContent:null})},
  // generalModalContent: null,
  // generalModalVariant: 'default',
  // setGeneralModalContent: (content,variant='default')=>{
  //   set({generalModalContent:content,generalModalVariant:variant})
  // },
  // closeGeneralModal: ()=>{set({generalModalContent:null})},
  // rightNavOpen: false,
  // setRightNavOpen: (newValue)=>{set({rightNavOpen:newValue})},
  // toggleRightNav: ()=>{
  //   const isOpen = get().rightNavOpen;
  //   get().setRightNavOpen(!isOpen);
  // },
  // globalPermsStates: {
  //   geolocation: false,
  //   camera: false
  // },
  // setGlobalPermsStates: (newGlobalPerms)=>{
  //   const existingGlobalPerms = get().globalPermsStates;
  //   set({
  //     globalPermsStates: {
  //       ...existingGlobalPerms,
  //       ...newGlobalPerms
  //     }
  //   })
  // },
  // currentObjective: '',
  // setCurrentObjective: (newObjective)=>{
  //   set({
  //     currentObjective:newObjective
  //   })
  // },
  // refreshCurrentObjective: async ()=>{
  //   await get().refreshMultipleDataSources(["world","group","player"]);
  //   get().resolveCurrentObjective();
  // },
  // resolveCurrentObjective: ()=>{
  //   const userData = get()?.userData;
  //   const groupData = get()?.groupData;
  //   const worldData = get()?.worldData;
  //     if (!userData || !groupData || !worldData) {console.log ('resolveCurrentObjective - missing user, group, or world data. Bailing.'); return}
  //     console.log('!OP resolveObjective... userData:',userData,'groupData',groupData,'worldData',worldData);
  //     if (worldData.overrideObjective) {
  //       console.log('!OP setting current objective to worldData.overrideObjective',worldData.overrideObjective)
  //       get().setCurrentObjective(worldData.overrideObjective);
  //     }
  //     else if (userData.currentObjective) {
  //       console.log('!OP setting current objective to userData.currentObjective',userData.currentObjective)
  //       get().setCurrentObjective(userData.currentObjective);
  //     }
  //     else if (groupData.currentObjective) {
  //       console.log('!OP setting current objective to groupData.currentObjective',groupData.currentObjective)
  //       get().setCurrentObjective(groupData.currentObjective);
  //     }
  //     else if (worldData.worldObjective) {
  //       console.log('!OP setting current objective to worldData.worldObjective',worldData.worldObjective)
  //       get().setCurrentObjective(worldData.worldObjective)
  //     }
  // },
  // debugEnabled: false,
  // setDebugEnabled: (newState)=>{
  //   set({debugEnabled:newState})
  // }
})));


export default useGlobalState;