import { useMemo } from "react";
import GalleryItem from "../GalleryItem"

const itemWidth = 1; // TODO dynamic item scale
const gutterWidth = 0.5; // TODO dynamic gutter width

const GalleryRow=({rowMeta={},...props})=>{
  console.log('!GR rowMeta',rowMeta)
  const startPos = useMemo(()=>{
    // half of full travel
    // full travel is ((numitems*itemWidth) + (numItems*gutter))  / 2
    // const fullTravel = (items.length * itemWidth);
    // return (-fullTravel/2)-(itemWidth/2);
    const fullTravel = (rowMeta.rowItems.length * (itemWidth+gutterWidth));
    return (-fullTravel/2) + ((itemWidth/2+gutterWidth/2));
  },[rowMeta])

  return (
    <group position={rowMeta.rowPosition || [0,0,0]} rotation={rowMeta.rowRotation || [0,0,0]} {...props}>
      {/* TODO: debug systems to conditionally enable below */}
      {/* <mesh>
        <sphereBufferGeometry args={[0.1]}/>  
        <meshBasicMaterial color={'#f00'}/>
      </mesh> */}
      
      {!!rowMeta && rowMeta.rowItems.map((item,idx)=>{
        console.log('adding item',item.itemName,'to row')
        return(
          <GalleryItem item={item} key={item.itemName} position={[startPos + (idx *itemWidth) + (idx * gutterWidth),0,0]}/>
        )
      })}
    </group>
  )
}

export default GalleryRow