// import styles from './styles.module.scss';
import { Button, TextField } from '@mui/material';
import LoginSignupSwitcher from '../LoginSignupSwitcher';
import { useRef, useState } from 'react';
import { Box } from '@mui/system';
import { useCallback } from 'react';
import { Auth } from 'aws-amplify';
import useGlobalState from '../GlobalStateManager';
import { useEffect } from 'react';

const LoginForm=({})=>{
    const {setLoadingModalContent, setUser, setMainModalContent} = useGlobalState((state)=>({setLoadingModalContent:state.setLoadingModalContent,setUser:state.setUser,setMainModalContent:state.setMainModalContent}))

    const emailRef = useRef();
    const passwordRef = useRef();

    const [generalError,setGeneralError] = useState();
    const [emailError,setEmailError] = useState();
    const [pwError,setPwError] = useState();

    const validateInputs = useCallback(()=>{
        const email = emailRef.current.querySelector('input').value
        const password = passwordRef.current.querySelector('input').value
        setEmailError('');
        setPwError('');
        if (!(/[a-zA-Z0-9@!$_\-.\(\)\[\]\#\~\:]{3,}@[a-zA-Z0-9@!$*_\-.\(\)\[\]\#\~\:]{3,}\..{2,}/gmi).test(email)) {
          setEmailError('Email must be a properly formatted email address (no spaces, no special chars)')
          return false;
        }
        else if (!(/[^\/\\\{\}\<\>]{4,}/gmi).test(password)) {
          setPwError('Please enter at least 4 characters.')
          setLoadingModalContent(null);
          return false;
        }
        return true;
    
    },[]);

    const resetFormErrors = useCallback(()=>{
        setGeneralError(null);
        setEmailError(null);
        setPwError(null);
    },[])

    const doLogin = useCallback((e)=>{
        e.preventDefault();
        if (!validateInputs()) return
        const email = emailRef.current.querySelector('input').value
        const password = passwordRef.current.querySelector('input').value
        
        Auth.signIn(email,password)
        .then((user)=>{
            setUser(user);
            setLoadingModalContent('Machine-elves building gallery...')
            setTimeout(()=>{setLoadingModalContent(null)},2500)
            setMainModalContent(null);
        })
    },[])

    return (
        <>
            <form onSubmit={doLogin}>
                <Box sx={{display:'flex',flexDirection:'column',padding:'20px'}}>
                    <h1>Login to Xen-Gallery</h1>
                    <TextField variant="outlined" size="small" helperText={emailError? emailError : "Email"} ref={emailRef} error={!!emailError} />
                    <TextField variant="outlined" size="small" helperText={pwError? pwError : "Password"} type="password"  ref={passwordRef} error={!!pwError}/>
                    <Button type="submit" variant="outlined">Log me in!</Button>
                </Box>
                <LoginSignupSwitcher currentDisplay='login'/>
            </form>
        </>
    )
}

export default LoginForm