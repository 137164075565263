import { degToRad } from "three/src/math/MathUtils"
import DrippingSkull from '../Assets/galleryAssets/drippingSkull.png';
import PlooshIdent from '../Assets/galleryAssets/vid/PlooshIdent.mp4'
import PlooshIdentThumb from '../Assets/galleryAssets/plooshIdentThumb.png'
import HomerDiptych from '../Assets/galleryAssets/homerDiptych.png'
import CornellFluidSwirl from '../Assets/galleryAssets/cornellFluidSwirl.png'
import PeptoPoolRoom from '../Assets/galleryAssets/peptoPoolRoom.png'
const Row1 = {
    rowName: 'row1',
    rowPosition: [-2.5,0,0],
    rowRotation: [0,degToRad(90),0], 
    rowItems: [
        {
            itemName: 'Dripping Skull',
            artistName: 'Teo Litto',
            productionYear: '2022',
            itemType: 'image', //only image for now but if god forbid we need to enhance we can add other types later like video, model, or experience
            itemAsset: DrippingSkull, //an image as pulled in with Import
            metadata: {
                //mandatory props
                dimensions: [2000,2000],
                descrip: 'C4d, xParticles, Octane, 2022',
                artistName: 'Teo Litto',
                forSale: true,
                artistContactEmail: 'teo@ultraplush.io',
                //arbitrary props allowed here
            }
        },
        {
            itemName: 'Ploosh Ident',
            artistName: 'Teo Litto',
            productionYear: '2022',
            itemType: 'vid', //only image for now but if god forbid we need to enhance we can add other types later like video, model, or experience
            itemAsset: PlooshIdentThumb, //an image as pulled in with Import
            metadata: {
                //mandatory props
                dimensions: [2000,2000],
                descrip: 'C4d, xParticles, Octane, 2022',
                artistName: 'Teo Litto',
                productionYear: '2022',
                forSale: false,
                artistContactEmail: 'teo@ultraplush.io',
                //arbitrary props allowed here
                vidSrc: PlooshIdent
            }
        },
        {
            itemName: 'Homer Diptych',
            artistName: 'Teo Litto',
            productionYear: '2022',
            itemType: 'image', //only image for now but if god forbid we need to enhance we can add other types later like video, model, or experience
            itemAsset: HomerDiptych, //an image as pulled in with Import
            metadata: {
                //mandatory props
                dimensions: [2000,1417],
                descrip: "D'oh! 2022",
                artistName: 'Teo Litto',
                forSale: true,
                artistContactEmail: 'teo@ultraplush.io',
                //arbitrary props allowed here
            }
        },
        {
            itemName: 'Cornell Fluid Swirl',
            artistName: 'Teo Litto',
            productionYear: '2022',
            itemType: 'image', //only image for now but if god forbid we need to enhance we can add other types later like video, model, or experience
            itemAsset: CornellFluidSwirl, //an image as pulled in with Import
            metadata: {
                //mandatory props
                dimensions: [2000,2000],
                descrip: "2022",
                artistName: 'Teo Litto',
                forSale: true,
                artistContactEmail: 'teo@ultraplush.io',
                //arbitrary props allowed here
            }
        },
        {
            itemName: 'Pepto Pool Room',
            artistName: 'Teo Litto',
            productionYear: '2022',
            itemType: 'image', //only image for now but if god forbid we need to enhance we can add other types later like video, model, or experience
            itemAsset: PeptoPoolRoom, //an image as pulled in with Import
            metadata: {
                //mandatory props
                dimensions: [2000,2000],
                descrip: "c4d, Octane, 2022",
                artistName: 'Teo Litto',
                forSale: true,
                artistContactEmail: 'teo@ultraplush.io',
                //arbitrary props allowed here
            }
        },
    ]
}

export default Row1;