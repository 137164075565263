import { degToRad } from "three/src/math/MathUtils"
import LiveBedlam from 'Assets/galleryAssets/liveBedlam.png';
import NatlCrash from 'Assets/galleryAssets/nationalCrash.png';
import Dailyish from 'Assets/galleryAssets/dailyish.png';
import Station from 'Assets/galleryAssets/station.png';
const Row4 = {
    rowName: 'row4',
    rowPosition: [-5.5,0,-4],
    rowRotation: [0,degToRad(180),0], 
    rowItems: [
        {
            itemName: 'Live Bedlam',
            itemType: 'image', //only image for now but if god forbid we need to enhance we can add other types later like video, model, or experience
            itemAsset: LiveBedlam, //an image as pulled in with Import
            metadata: {
                //mandatory props
                dimensions: [2000,2000],
                descrip: "This is a puzzle. I'll know if you solve it. 2021",
                artistName: 'Teo Litto',
                forSale: false,
                artistContactEmail: 'teo@ultraplush.io',
                //arbitrary props allowed here
            }
        },
        {
            itemName: 'National Crash Cover',
            itemType: 'image', //only image for now but if god forbid we need to enhance we can add other types later like video, model, or experience
            itemAsset: NatlCrash, //an image as pulled in with Import
            metadata: {
                //mandatory props
                dimensions: [2000,2000],
                descrip: 'C4d,  Octane, 2021',
                artistName: 'Teo Litto',
                forSale: false,
                artistContactEmail: 'teo@ultraplush.io',
            }
        },
        {
            itemName: 'Dailyish',
            itemType: 'image', //only image for now but if god forbid we need to enhance we can add other types later like video, model, or experience
            itemAsset: Dailyish, //an image as pulled in with Import
            metadata: {
                //mandatory props
                dimensions: [2000,2000],
                descrip: 'C4d, Cycles, 2021',
                artistName: 'Teo Litto',
                forSale: true,
                artistContactEmail: 'teo@ultraplush.io',
                //arbitrary props allowed here
            }
        },
        {
            itemName: 'Station',
            itemType: 'image', //only image for now but if god forbid we need to enhance we can add other types later like video, model, or experience
            itemAsset: Station, //an image as pulled in with Import
            metadata: {
                //mandatory props
                dimensions: [2000,2000],
                descrip: '2015',
                artistName: 'Teo Litto',
                forSale: false,
                artistContactEmail: 'teo@ultraplush.io',
                //arbitrary props allowed here
            }
        },
    ]
}

export default Row4