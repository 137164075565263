// import styles from './styles.module.scss';
import { useThree } from '@react-three/fiber'
 import {KeyboardControls } from "@react-three/drei"
import useGlobalState from '../../GlobalStateManager'
import { useCallback } from 'react'
const PlayerControl=({paused,children})=>{
  return (
    <KeyboardControls
    map={[
      { name: "forward", keys: ["ArrowUp", "w", "W"] },
      { name: "backward", keys: ["ArrowDown", "s", "S"] },
      { name: "left", keys: ["ArrowLeft", "a", "A"] },
      { name: "right", keys: ["ArrowRight", "d", "D"] },
      { name: "jump", keys: ["Space"] },
    ]}>
      {children}
    </KeyboardControls>
  )
}

export default PlayerControl