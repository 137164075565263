
import Box from '@mui/material/Box';

// import { Grid, Paper } from '@mui/material';
import HeaderBar from 'Components/HeaderBar';
import { useEffect } from 'react';
import UPBadge from '../../../Components/UPBadge';
import GalleryDetailModal from '../../../Components/GalleryDetailModal';
import GeneralModal from '../../../Components/GeneralModal';
import useGlobalState from '../../../Components/GlobalStateManager';
import LoadingModal from '../../../Components/LoadingModal';

const BasePageTemplate = ({children,showBadge=true,showHeaderBar=true,...props})=>{

  return (
    <>
      {showHeaderBar && <HeaderBar />}  
      {showBadge && <UPBadge/>}
      <LoadingModal />
      <GeneralModal />
      <GalleryDetailModal />
      <Box component="main" className="mui-root" sx={{ flexGrow: 1, m: 0, p: 4, position: 'relative', top:'0', minHeight: '100vh', padding:`0 0, 0, 0;`,display:'flex',justifyContent:"middle",alignItems:"flex-start",textAlign:"center"}}>
        {children}
      </Box>
    </>
  )
}

export default BasePageTemplate;