import styles from './styles.module.scss';
import { Button, ButtonGroup, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';
import useGlobalState from '../GlobalStateManager';
import LoginForm from '../LoginForm';
import SignUpForm from '../SignUpForm';

const LoginSignupSwitcher=({currentDisplay="signup"})=>{ 
  const {setLoadingModalContent, setMainModalContent} = useGlobalState((state)=>({setLoadingModalContent:state.setLoadingModalContent,setMainModalContent:state.setMainModalContent}))
  const gotoLogin = useCallback((e)=>{
    e.preventDefault();
    setMainModalContent(<LoginForm/>)
  },[])

  const gotoSignup = useCallback((e)=>{
    e.preventDefault();
    setMainModalContent(<SignUpForm/>)
  },[])

  const loginProblems = useCallback((e)=>{
    e.preventDefault();
    window.open(`mailto:hello@ultraplush.io`)
  },[])
  return (
    <>
        <Grid container spacing={1} sx={{width:'90%', margin:'0 auto', paddingBottom:'15px'}}>
          <Grid item xs={5} sx={{textAlign:'center', padding: '0'}}>
            {currentDisplay==="signup" &&
              <Typography variant="caption"><a href="" onClick={gotoLogin}>I already have a ticket, log me in, please!</a></Typography>
            }
            {currentDisplay==="login" &&
             <Typography variant="caption"><a href="" onClick={gotoSignup}>I need a ticket! Take me to signup, please.</a></Typography>
            }
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={5} sx={{textAlign:'center', padding: '0'}}>
            <Typography variant="caption"><a href="" onClick={loginProblems}>Help! I'm having trouble logging in.</a></Typography>
          </Grid>
        </Grid>
    </>
  )
}

export default LoginSignupSwitcher