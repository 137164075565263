import { Box, Button, Grid, Typography } from '@mui/material';
import { useCallback, useEffect, useRef } from 'react';
import useGlobalState from '../GlobalStateManager';
import styles from './styles.module.scss';

const GallItemInfo=({item})=>{
    const {setMainModalContent} = useGlobalState((state)=>({setMainModalContent:state.setMainModalContent}))
    const escapeListener = useRef(null);
    const closeModal = useCallback(()=>{
        setMainModalContent(null);
    },[])
    useEffect(()=>{
        if (escapeListener.current) window.removeEventListener('keyup',escapeListener.current);
        escapeListener.current = window.addEventListener('keyup',()=>{ 
            window.removeEventListener('keyup',escapeListener.current);
            escapeListener.current=null;
        })
    },[])

    const openMailTo = useCallback(()=>{
        window.open(`mailto:${item?.metadata?.artistContactEmail}`)
    },[item])

  return (
    <Box onClick={closeModal} sx={{textAlign:'center'}}>
        {item.itemType=="image" && <img src={item.itemAsset} className={styles.imagePrev}/>}
        {item.itemType=="vid" && <video className={styles.vidPrev} controls><source src={item.metadata.vidSrc} type="video/mp4"/></video>}
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h4">{item.itemName}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle2" component='span'>by </Typography>
                <Typography variant="body">{item.metadata.artistName || item.artistName}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle2">{item.productionYear}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body">{item.metadata.descrip}</Typography>
            </Grid>
            {item.forSale && <Grid item xs={12}>
                <Typography variant="subtitle1">For sale.</Typography>
            </Grid>}
            {!item.forSale && <Grid item xs={12}>
                <Typography variant="subtitle1">Not for sale.</Typography>
            </Grid>}
            <Grid item xs={12}>
                {item?.metadata?.artistContactEmail && <Button variant="outlined" onClick={openMailTo} sx={{margin:'10px'}}>E-mail Artist</Button>}
            </Grid>
        </Grid>
    </Box>
  )
}

export default GallItemInfo