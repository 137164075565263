import logo from './logo.svg';
import './App.scss';
import { Amplify } from 'aws-amplify';
import awsmobile from './aws-exports'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import LoginSignupPage from 'Pages/LoginSignup';
import RouteErrorPage from 'Pages/RouteError';
import HomePage from 'Pages/Home';
import LogoutPage from 'Pages/Logout';

import MemberGate from './Components/MemberGate';
import SalesPage from './Pages/Sales';
import { ThemeProvider } from '@emotion/react';
import { createMuiTheme, createTheme } from '@mui/material';

// const config = { publicKey: "pk_c5e3c3ccf116d9b920b7" }

Amplify.configure(awsmobile);

const router = createBrowserRouter([
  {
    path: "/",
    element: <SalesPage/>,
  },
  {
    path: "/app",
    element: <MemberGate><HomePage/></MemberGate>,
  },
]);

const theme = createTheme({
  typography: {
   "fontFamily": `"vox", "Helvetica", "Arial", sans-serif`,
   "fontSize": 14,
   "fontWeightLight": 300,
   "fontWeightRegular": 400,
   "fontWeightMedium": 500
  }
});

function App() {
  return (
      <div className="Xen-Gallery"> 
        <ThemeProvider theme={theme}>
          <RouterProvider router={router} />  
        </ThemeProvider>
        
      </div>
  );
}

export default App;


