// import styles from './styles.module.scss';
import RedVelvetRope from 'Assets/models/velrope_cleaned.glb';
import {useGLTF} from '@react-three/drei';
import galaxy1 from 'Assets/tex/skybox_galaxy_1_2048.jpeg';
import { useLoader } from '@react-three/fiber';
import { TextureLoader } from 'three';
const VelRope=({position=[0,0,0],...props})=>{
    const _envTex = useLoader(TextureLoader, galaxy1);  
    const VelRopeModel = useGLTF(RedVelvetRope);
    return (
        <>
            <group position={[position[0],position[1]-1,position[2]]} {...props}>
                <mesh geometry={VelRopeModel.nodes.Posts.geometry}>
                    <meshPhysicalMaterial color={"#ffe666"} roughness={0} reflectivity={0} envMap={_envTex} metalness={1}  />
                </mesh>
                <mesh geometry={VelRopeModel.nodes.velvet.geometry}>
                    <meshPhysicalMaterial color={"#b22"} roughness={1}/>
                </mesh>
            </group>
        </>
    )
}

export default VelRope