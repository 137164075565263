import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import BasePageTemplate from '../Templates/BasePageTemplate';
import styles from './styles.module.scss';
import SalesPageTitle from 'Assets/img/SalesPageTitle.png'
import UPSticker from 'Assets/img/UltraPlushSticker_c.png'
import BuyTixBtn from 'Assets/img/buyTixBtn.png'

import { Link } from 'react-router-dom';
const SalesPage=({})=>{
  return (
    <BasePageTemplate showHeaderBar={false}>
        <Box>
            <img src={UPSticker} style={{width: '300px', display: 'block', margin: '30px auto 0 auto'}} />
            <span style={{display:'block',fontFamily:'"vox", sans-serif', fontWeight:'400', fontSize:'21px', color:'#fff', position: 'relative', top: '-20px', opacity: '0.6'}}>Presents</span>
            <Grid container sx={{position:'relative',top:'-153px',justifyContent:'center'}}>
                <Grid item xs={12}>
                    <img src={SalesPageTitle} style={{width:'100%'}} />
                </Grid>
                <Grid item xs={12} sx={{marginTop:'-110px'}}>
                    <Typography variant="h3" sx={{color:'#fff'}}>Bear witness to the Future of Art</Typography>
                </Grid>
                <Grid item xs={9} >
                    <Typography variant="h4" sx={{color:'#fff'}}>Experience Ultra Plush’s cloud-based, virtual-world gallery with in-built AR experiences and optional web-VR!</Typography>
                </Grid>
                <Grid item xs={9} >
                    <Link to="/app"><img src={BuyTixBtn} style={{width:'400px', marginTop: '60px'}} /></Link>
                </Grid>
                <Grid item xs={9} >
                    <Typography variant="h4" sx={{color:'#fff', marginTop: '60px'}}>Come view exciting contemporary digital art by some of the world’s most intelligent and attractive people!</Typography>
                </Grid>
                <Grid item xs={9} >
                    <Link to="/app"><img src={BuyTixBtn} style={{width:'400px', marginTop: '60px'}} /></Link>
                </Grid>
                <Grid item xs={9} >
                    <Typography variant="h4" sx={{color:'#fff', marginTop: '60px'}}>Experience new AR demos and QR-Driven experiences before they are gone!</Typography>
                </Grid>
                <Grid item xs={9} >
                    <Link to="/app"><img src={BuyTixBtn} style={{width:'400px', marginTop: '60px'}} /></Link>
                </Grid>
                <Grid item xs={9} sx={{marginTop: '40px'}} >
                    <Typography variant="body" sx={{color:'#fff'}}>Xen-Gallery is an Ultra Plush jam © 2023 All Rights Reserved.</Typography>
                </Grid>
                <Grid item xs={9} sx={{marginTop: '80px'}} >
                    <img src={UPSticker} style={{width: '600px', display: 'block', margin: '30px auto 0 auto'}} />
                </Grid>
            </Grid>
        </Box>
    </BasePageTemplate>
  )
}

export default SalesPage