import { degToRad } from "three/src/math/MathUtils"
import SplitMind from 'Assets/galleryAssets/skullSplit.png';
import SplitMindvid from 'Assets/galleryAssets/vid/skullSplit1.mp4';
import ImNotGaussed from 'Assets/galleryAssets/ImNotGaussed.png';
import SpiritCrystalQR from 'Assets/galleryAssets/spiritCrystalQR.png';
import CityGritEyeballMassage from 'Assets/galleryAssets/cityGritEyeballMassage.png';
const Row5 = {
    rowName: 'row5',
    rowPosition: [5.5,0,-4],
    rowRotation: [0,degToRad(180),0], 
    rowItems: [
        {
            itemName: 'Spirit Crystal Demo',
            itemType: 'image', //only image for now but if god forbid we need to enhance we can add other types later like video, model, or experience
            itemAsset: SpiritCrystalQR, //an image as pulled in with Import
            metadata: {
                //mandatory props
                dimensions: [2000,2000],
                descrip: 'Code',
                artistName: 'Teo Litto',
                forSale: false,
                artistContactEmail: 'teo@ultraplush.io',
                //arbitrary props allowed here
            }
        },
        {
            itemName: 'Split Mind',
            itemType: 'vid', //only image for now but if god forbid we need to enhance we can add other types later like video, model, or experience
            itemAsset: SplitMind, //an image as pulled in with Import
            metadata: {
                //mandatory props
                dimensions: [2000,2000],
                descrip: 'C4D Octane 2018',
                artistName: 'Teo Litto',
                forSale: false,
                artistContactEmail: 'teo@ultraplush.io',
                //arbitrary props allowed here
                vidSrc: SplitMindvid
            }
        },
        {
            itemName: "City Grit Eyeball Massage",
            itemType: 'image', //only image for now but if god forbid we need to enhance we can add other types later like video, model, or experience
            itemAsset: CityGritEyeballMassage, //an image as pulled in with Import
            metadata: {
                //mandatory props
                dimensions: [2000,2000],
                descrip: 'Photo',
                artistName: 'Teo Litto',
                forSale: false,
                artistContactEmail: 'teo@ultraplush.io',
            }
        },
        {
            itemName: "I'm Not Gaussed",
            itemType: 'image', //only image for now but if god forbid we need to enhance we can add other types later like video, model, or experience
            itemAsset: ImNotGaussed, //an image as pulled in with Import
            metadata: {
                //mandatory props
                dimensions: [2000,2000],
                descrip: 'Device',
                artistName: 'Teo Litto',
                forSale: false,
                artistContactEmail: 'teo@ultraplush.io',
            }
        },

    ]
}

export default Row5