import styles from './styles.module.scss';

import { useEffect, useMemo } from "react"
import { NavLink } from "react-router-dom"
import useGlobalState from "../GlobalStateManager"
import { Box, Grid, Typography } from '@mui/material';
import { useCallback } from 'react';
import { Auth } from 'aws-amplify';

const HeaderBar=({})=>{

  const {user} = useGlobalState((state)=>({user:state.user}));


  // useEffect(()=>{
  //   setFoo('bar');
  // },[])
  
  // useEffect(()=>{console.log('foo change',foo)},[foo])

  const activeStyle = useMemo(()=>{
    return {
      textDecoration: "none",
      color: '#333',
      pointerEvents: 'none'
    }
  })
  const inactiveStyle = useMemo(()=>{
    return {}
  })

  const doLogout = useCallback(()=>{
    Auth.logout();
  },[])

  return (
    <Box className={styles.headerBar}>
      <Grid container spacing={1} sx={{padding: '0 20px'}}>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <Typography>Xen Gallery</Typography>
        </Grid>
        <Grid item xs={4} sx={{textAlign:'right'}}>
        {user &&
          <a href="" onClick={doLogout}>Log out</a> 
        }
        </Grid>
      </Grid>
    </Box>
  )
}

export default HeaderBar