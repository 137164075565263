// import styles from './styles.module.scss';

import * as THREE from "three"
import { useCallback, useRef } from "react"
import { useFrame } from "@react-three/fiber"
import * as RAPIER from "@dimforge/rapier3d-compat"
import { useKeyboardControls } from "@react-three/drei"
import { CapsuleCollider, RigidBody, useRapier } from "@react-three/rapier"
import { degToRad } from "three/src/math/MathUtils"

const SPEED = 5
const direction = new THREE.Vector3()
const frontVector = new THREE.Vector3()
const sideVector = new THREE.Vector3()
const rotation = new THREE.Vector3()



const PlayerAvatar=({paused = false,position=[0,0,6],...props})=>{
    const rapier = useRapier();
    const [, get] = useKeyboardControls();
    const bodyRef = useRef();
    useFrame((state) => {
        if (paused) {return;}
        bodyRef.current?.wakeUp();
        const { forward, backward, left, right, jump } = get()
        const velocity = bodyRef.current.linvel();    
        // update camera
        const bodyTranslation = bodyRef.current.translation()
        state.camera.position.set(bodyTranslation.x,bodyTranslation.y,bodyTranslation.z);
        // movement
        frontVector.set(0, 0, backward - forward)
        sideVector.set(left - right, 0, 0)
        direction.subVectors(frontVector, sideVector).normalize().multiplyScalar(SPEED).applyEuler(state.camera.rotation)
        bodyRef.current.setLinvel({ x: direction.x, y: velocity.y, z: direction.z });
        // jumping
        const world = rapier.world.raw()
        const ray = world.castRay(new RAPIER.Ray(bodyRef.current.translation(), { x: 0, y: -1, z: 0 }))
        const grounded = ray && ray.collider && Math.abs(ray.toi) <= 1.75
        if (jump && grounded) bodyRef.current.setLinvel({ x: 0, y: 7.5, z: 0 })
    })

    return (
    <>
         <RigidBody ref={bodyRef} colliders={false} mass={0} type="dynamic" position={[position[0], 0.1, position[2]]} rotation={[0,degToRad(180),0]} enabledRotations={[false, false, false]}>
            <CapsuleCollider args={[0.75, 0.5]} />
        </RigidBody>
    </>
    )
}

export default PlayerAvatar