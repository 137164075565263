import useGlobalState from 'Components/GlobalStateManager';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useCallback, useEffect } from 'react';
const GalleryDetailModal=({})=>{
  const {galleryDetailModalContent,setGalleryDetailModalContent, userControlsPaused, setUserControlsPaused} = useGlobalState((state)=>({galleryDetailModalContent:state.galleryDetailModalContent, setGalleryDetailModalContent: state.setGalleryDetailModalContent,pointerLockRef:state.pointerLockRef,userControlsPaused:state.setUserControlsPaused, setUserControlsPaused:state.setUserControlsPaused}));    
  
  useEffect(()=>{
    if (!!galleryDetailModalContent) {
        // setTimeout(()=>{pointerLockRef.current.unlock();},50)
        setUserControlsPaused(true)
    }
    else {
        setUserControlsPaused(false);
    }
  },[galleryDetailModalContent])
  
  const doClose = useCallback(()=>{
    setGalleryDetailModalContent(null);
    // pointerLockRef.current.lock();
    setUserControlsPaused(false);
  },[])

  const doClick = useCallback((e)=>{
    e.preventDefault();
    e.stopPropagation();
  },[])

  const doKeyUp = useCallback((e)=>{
    e.preventDefault();
    e.stopPropagation();
  },[])

  if (!galleryDetailModalContent) return null;
  return (
    <>
        <Modal
                open={!!galleryDetailModalContent}
                onClose={doClose}
                onKeyUp={doKeyUp}
            >
            <Box onClick={doClick} sx={{background:'#fff', width: '70%', overflow:'auto', maxHeight:'calc(95vh - 70px)', position: 'absolute', left: '50%', top:'70px', transform: 'translate3d(-50%,0,0)'}}>
                {galleryDetailModalContent}
            </Box>
        </Modal>
    </>
  )
}

export default GalleryDetailModal