// import styles from './styles.module.scss';
import useGlobalState from 'Components/GlobalStateManager';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
const GeneralModal=({})=>{
    const {modalContent,setModalContent} = useGlobalState((state)=>({modalContent:state.mainModalContent,setModalContent:state.setMainModalContent}));

    return (
        <>
            <Modal
                open={!!modalContent}
                onClose={()=>{setModalContent(null)}}
            >
                <Box sx={{background:'#fff', maxWidth: '50vw', minWidth: '25vw', position: 'absolute', left: '50%', top:'20%', transform: 'translate3d(-50%,0,0)'}}>
                    {modalContent}
                </Box>
            </Modal>
        </>
    )
}

export default GeneralModal