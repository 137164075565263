import { Box } from '@mui/system';
// import styles from './styles.module.scss';
import UPSticker from 'Assets/img/UltraPlushSticker_c.png'
const UPBadge=({})=>{
  return (
    <>
        <Box sx={{position: 'fixed', top:'25px', left: '0', zIndex:'9', width: '150px;'}}>
            <img src={UPSticker} style={{width:'100%',height:'auto'}}/>
        </Box>
    </>
  )
}

export default UPBadge