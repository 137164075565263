//import styles from './styles.module.scss';
import WorldBase from "../../Components/World/WorldBase"
import BasePageTemplate from "../Templates/BasePageTemplate"
const HomePage=({})=>{
  return (
    <BasePageTemplate>
      <WorldBase/>
    </BasePageTemplate>
  )
}

export default HomePage