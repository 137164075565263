import styles from './styles.module.scss';
import useGlobalState from 'Components/GlobalStateManager';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
const LoadingModal=({})=>{
    const {modalContent} = useGlobalState((state)=>({modalContent:state.loadingModalContent}));

    return (
        <>
            <Modal
                open={!!modalContent}
                onClose={()=>{}}
            >
                <Box sx={{background:'#fff', maxWidth: '50vw', minWidth: '25vw', position: 'absolute', left: '50%', top:'20%', transform: 'translate3d(-50%,-50%,0)',zIndex:'5', display: 'flex', flexDirection:'column', justifyContent: 'center',alignItems:'center'}}>
                    {modalContent}
                    <div className={styles['dual-ring-spinner']}></div>
                </Box>
            </Modal>
        </>
    )
}

export default LoadingModal