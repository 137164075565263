// import styles from './styles.module.scss';
import useGlobalState from 'Components/GlobalStateManager';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
const NameModal=({})=>{
    const {modalContent} = useGlobalState((state)=>({modalContent:state.nameModalContent}));

    return (
        <>
            <Modal
                hideBackdrop
                open={!!modalContent}
                // onClose={()=>{alert('close')}}
            >
                <Box sx={{background:'#fff', maxWidth: '50vw', minWidth: '20vw', position: 'absolute', left: '50%', bottom:'20%', transform: 'translate3d(-50%,0,0)'}}>
                    {modalContent}
                </Box>
            </Modal>
        </>
    )
}

export default NameModal