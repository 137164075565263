import { useThree } from '@react-three/fiber'
import { BackSide, TextureLoader, WebGLCubeRenderTarget } from 'three';
import { useLoader, useFrame } from "@react-three/fiber";
import galaxy1 from 'Assets/tex/skybox_galaxy_1_2048.jpeg';
import { useEffect, useMemo, useRef } from 'react';
import {EquirectangularRefractionMapping} from 'three'
import { degToRad } from 'three/src/math/MathUtils';
const SkyBox=({tex=galaxy1})=>{
  const _tex = useLoader(TextureLoader, tex); 
  const sphereRef = useRef();
  useEffect(()=>{
    _tex.flipY = false;
    _tex.mapping = EquirectangularRefractionMapping;
    _tex.center.y=40;
    // scene.background = _tex;
  },[_tex])
  useFrame(()=>{
    sphereRef.current.rotation.y -= .00009;
  },[])
  return (
    <>
      <mesh ref={sphereRef} rotation={[0,degToRad(-120),0]}>
        <sphereBufferGeometry args={[100,15]} />
        <meshBasicMaterial map={_tex} side={BackSide}  />
      </mesh>
    </>
  )
}

export default SkyBox