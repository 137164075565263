import { CuboidCollider, RigidBody } from "@react-three/rapier"
const Invisiwall=({position=[0,0,0],rotation=[0,0,0],width=1,height=1,showDebug=false,...props})=>{
  return (
    <>
         {!!showDebug &&<mesh position={position} rotation={rotation} {...props} >
            <planeBufferGeometry args={[width,height,1]} />
           <meshBasicMaterial color={'#f00'} wireframe={true} />
        </mesh>}
        
        <RigidBody {...props} type="fixed" colliders={false}>
            <CuboidCollider args={[width*0.5, height*0.5, .01]} position={position} rotation={rotation}/>
        </RigidBody>
    </>
  )
}

export default Invisiwall   