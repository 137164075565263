import { degToRad } from "three/src/math/MathUtils"
import StackedCyls from 'Assets/galleryAssets/stackedCyls.png'
import StackedCylsvid from 'Assets/galleryAssets/vid/stackedCyls.mp4'
import AlphaA from 'Assets/galleryAssets/alpha.png'
import FluidTest3080 from 'Assets/galleryAssets/3080_Fluid_Test.png'
import FluidTest3080vid from 'Assets/galleryAssets/vid/3080FluidTest1_1.mp4';
import AmberSwarm from 'Assets/galleryAssets/amberSwarm.png';
import LuminantSwarm from 'Assets/galleryAssets/luminantSwarm.png';
import OrangeSwarm from 'Assets/galleryAssets/orangeSwarm.png';
import Skyral from 'Assets/galleryAssets/skyral.png';
import Skyralvid from 'Assets/galleryAssets/vid/Skyral.mp4';
import PlooshPitMulti from 'Assets/galleryAssets/plooshPitMulti.png';
import PlooshPitMultivid from 'Assets/galleryAssets/vid/plooshPitMulti.mp4';
import Arrival from 'Assets/galleryAssets/arrival.png';
import Arrivalvid from 'Assets/galleryAssets/vid/Arrival.mp4';
import PlooshPitMultiVid from 'Assets/galleryAssets/vid/plooshPitMulti.mp4';
import FromHeadToHand from 'Assets/galleryAssets/fromHeadToHand.png';
import FromHeadToHandvid from 'Assets/galleryAssets/vid/fromHeadToHand.mp4';
import LiveBedlam from 'Assets/galleryAssets/liveBedlam.png'
const Row3 = {
    rowName: 'row3',
    rowPosition: [0,0,-10],
    rowRotation: [0,0,0], 
    rowItems: [
        {
            itemName: 'Stacked Cyls',
            itemType: 'vid', //only image for now but if god forbid we need to enhance we can add other types later like video, model, or experience
            itemAsset: StackedCyls, //an image as pulled in with Import
            metadata: {
                //mandatory props
                dimensions: [2000,2000],
                descrip: 'C4D, Octane, 2022',
                artistName: 'Teo Litto',
                forSale: true,
                artistContactEmail: 'teo@ultraplush.io',
                //arbitrary props allowed here
                vidSrc: StackedCylsvid
            }
        },
        {
            itemName: 'Aalpha',
            itemType: 'image', //only image for now but if god forbid we need to enhance we can add other types later like video, model, or experience
            itemAsset: AlphaA, //an image as pulled in with Import
            metadata: {
                //mandatory props
                dimensions: [2000,2000],
                descrip: 'C4D, Octane, 2021',
                artistName: 'Teo Litto',
                forSale: true,
                artistContactEmail: 'teo@ultraplush.io',
                //arbitrary props allowed here
            }
        },
        {
            itemName: 'FluidTest3080',
            itemType: 'vid', //only image for now but if god forbid we need to enhance we can add other types later like video, model, or experience
            itemAsset: FluidTest3080, //an image as pulled in with Import
            metadata: {
                //mandatory props
                dimensions: [2000,2000],
                descrip: 'C4d, Octane, 2022',
                artistName: 'Teo Litto',
                forSale: true,
                artistContactEmail: 'teo@ultraplush.io',
                //arbitrary props allowed here
                vidSrc: FluidTest3080vid
            }
        },
        {
            itemName: 'Amber Swarm',
            itemType: 'image', //only image for now but if god forbid we need to enhance we can add other types later like video, model, or experience
            itemAsset: AmberSwarm, //an image as pulled in with Import
            metadata: {
                //mandatory props
                dimensions: [2000,2000],
                descrip: 'C4d, xParticles, Octane, 2021',
                artistName: 'Teo Litto',
                forSale: true,
                artistContactEmail: 'teo@ultraplush.io',
                //arbitrary props allowed here
            }
        },
        {
            itemName: 'Luminant Swarm',
            itemType: 'image', //only image for now but if god forbid we need to enhance we can add other types later like video, model, or experience
            itemAsset: LuminantSwarm, //an image as pulled in with Import
            metadata: {
                //mandatory props
                dimensions: [2000,2000],
                descrip: "c4d, xParticles, Octane 2022",
                artistName: 'Teo Litto',
                forSale: true,
                artistContactEmail: 'teo@ultraplush.io',
                //arbitrary props allowed here
                // vidSrc: 
            }
        },
        {
            itemName: 'Orange Swarm',
            itemType: 'image', //only image for now but if god forbid we need to enhance we can add other types later like video, model, or experience
            itemAsset: OrangeSwarm, //an image as pulled in with Import
            metadata: {
                //mandatory props
                dimensions: [2000,2000],
                descrip: '2022',
                artistName: 'Teo Litto',
                forSale: true,
                artistContactEmail: 'teo@ultraplush.io',
                //arbitrary props allowed here
            }
        },
        {
            itemName: 'Skyral',
            itemType: 'image', //only image for now but if god forbid we need to enhance we can add other types later like video, model, or experience
            itemAsset: Skyral, //an image as pulled in with Import
            metadata: {
                //mandatory props
                dimensions: [2000,2000],
                descrip: 'C4d, xParticles, Octane, 2022',
                artistName: 'Teo Litto',
                forSale: false,
                artistContactEmail: 'teo@ultraplush.io',
            }
        },
        {
            itemName: 'Ploosh Pit Multi',
            itemType: 'vid', //only image for now but if god forbid we need to enhance we can add other types later like video, model, or experience
            itemAsset: PlooshPitMulti, //an image as pulled in with Import
            metadata: {
                //mandatory props
                dimensions: [2000,2000],
                descrip: 'C4d, xParticles, Octane, 2022',
                artistName: 'Teo Litto',
                forSale: false,
                artistContactEmail: 'teo@ultraplush.io',
                //arbitrary props allowed here
                vidSrc: PlooshPitMultivid
            }
        },
        {
            itemName: 'Arrival',
            itemType: 'vid', //only image for now but if god forbid we need to enhance we can add other types later like video, model, or experience
            itemAsset: Arrival, //an image as pulled in with Import
            metadata: {
                //mandatory props
                dimensions: [2000,2000],
                descrip: 'C4d, Octane, 2021',
                artistName: 'Teo Litto',
                forSale: false,
                artistContactEmail: 'teo@ultraplush.io',
                //arbitrary props allowed here
                vidSrc: Arrivalvid
            }
        },
        {
            itemName: 'From Head to Hand',
            itemType: 'vid', //only image for now but if god forbid we need to enhance we can add other types later like video, model, or experience
            itemAsset: FromHeadToHand, //an image as pulled in with Import
            metadata: {
                //mandatory props
                dimensions: [2000,2000],
                descrip: "After Effects 2022. Recommended Listening: MACINTOSH PLUS - リサフランク420 / 現代のコンピュ",
                artistName: 'Teo Litto',
                forSale: false,
                artistContactEmail: 'teo@ultraplush.io',
                //arbitrary props allowed here
                vidSrc: FromHeadToHandvid
            }
        },
    ]
}

export default Row3