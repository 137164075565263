import styles from './styles.module.scss';
import { useState,useEffect } from 'react';
import LoginSignupPage from 'Pages/LoginSignup';
import useGlobalState from '../GlobalStateManager';
const MemberGate=({children})=>{
  const {user,setModalContent} = useGlobalState((state)=>({user:state.user,setModalContent:state.setMainModalContent}));
  
  return (
    // <>
    //   {children}
    //    {/* TODO REENABLE MEMBERGATE */}
    // </>
    <>
      {!user && <>
        <LoginSignupPage/>
      </>}
      {!!user && <>
        {children} 
      </>}
    </>
  )
}

export default MemberGate