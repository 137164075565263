import { degToRad } from "three/src/math/MathUtils"
import Brolumbo from '../Assets/galleryAssets/brolumbo.png';
import IceBalls from '../Assets/galleryAssets/iceBalls.png';
import MonoTemple from '../Assets/galleryAssets/monoTemple.png';
import EmergentPhenomena from '../Assets/galleryAssets/emergentPhenomena.png';
import PlooshPitOrangeThumb from '../Assets/galleryAssets/plooshPitOrange.png';
// import PlooshPitOrange from '../Assets/galleryAssets/plooshPitOrange.mp4';
import CornellFluidSwirl from '../Assets/galleryAssets/cornellFluidSwirl.png'
const Row2 = {
    rowName: 'row2',
    rowPosition: [2.5,0,0],
    rowRotation: [0,degToRad(-90),0], 
    rowItems: [
        {
            itemName: 'Brolumbo Series Cover',
            itemType: 'image', //only image for now but if god forbid we need to enhance we can add other types later like video, model, or experience
            itemAsset: Brolumbo, //an image as pulled in with Import
            metadata: {
                //mandatory props
                dimensions: [2000,2000],
                descrip: '2022',
                artistName: 'Teo Litto',
                forSale: false,
                artistContactEmail: 'teo@ultraplush.io',
                //arbitrary props allowed here
            }
        },
        {
            itemName: 'Codename: Iceballs Cover',
            itemType: 'image', //only image for now but if god forbid we need to enhance we can add other types later like video, model, or experience
            itemAsset: IceBalls, //an image as pulled in with Import
            metadata: {
                //mandatory props
                dimensions: [2000,2000],
                descrip: 'C4d, xParticles, Octane, 2022',
                artistName: 'Teo Litto',
                forSale: false,
                artistContactEmail: 'teo@ultraplush.io',
            }
        },
        {
            itemName: 'MonoTemple',
            itemType: 'image', //only image for now but if god forbid we need to enhance we can add other types later like video, model, or experience
            itemAsset: MonoTemple, //an image as pulled in with Import
            metadata: {
                //mandatory props
                dimensions: [2000,2000],
                descrip: 'C4d, Octane, 2022',
                artistName: 'Teo Litto',
                forSale: true,
                artistContactEmail: 'teo@ultraplush.io',
                //arbitrary props allowed here
            }
        },
        {
            itemName: 'Emergent Phenomena',
            itemType: 'image', //only image for now but if god forbid we need to enhance we can add other types later like video, model, or experience
            itemAsset: EmergentPhenomena, //an image as pulled in with Import
            metadata: {
                //mandatory props
                dimensions: [2000,2000],
                descrip: 'C4d, Octane, 2021',
                artistName: 'Teo Litto',
                forSale: true,
                artistContactEmail: 'teo@ultraplush.io',
                //arbitrary props allowed here
            }
        },
        {
            itemName: 'Ploosh Pit Orange',
            itemType: 'image', //only image for now but if god forbid we need to enhance we can add other types later like video, model, or experience
            itemAsset: PlooshPitOrangeThumb, //an image as pulled in with Import
            metadata: {
                //mandatory props
                dimensions: [2000,2000],
                descrip: "c4d, xParticles, Octane 2022",
                artistName: 'Teo Litto',
                forSale: false,
                artistContactEmail: 'teo@ultraplush.io',
                //arbitrary props allowed here
                // vidSrc: 
            }
        },
    ]
}

export default Row2